import { Injectable } from '@angular/core';
import { HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, TimeoutError } from 'rxjs';
import { tap, timeout } from 'rxjs/operators';
import { HttpInterceptorSnackBarMessagesService } from './snack-bar-messages';
import { isRequestURLAppAPI } from './request.helpers';
import { AppHTTPrequestExtraProperties } from '../helpers/app-http-request-extra-properties';
import { CustomHttpErrorResponse, CustomHttpRequest } from './custom-erro-response';

@Injectable({
	providedIn: 'root'
})
export class ServerErrorService {

	constructor(
		private appHTTPrequestExtraProperties: AppHTTPrequestExtraProperties,
		private httpInterceptorSnackBarMessagesService: HttpInterceptorSnackBarMessagesService
	) {}

	interceptErrors(error: CustomHttpErrorResponse) {

		const isTimeout = error instanceof TimeoutError;

		if(!isTimeout && error.error && !error.error.friendlyMessage){
			error.error.friendlyMessage = {};
		}

		if (
			isTimeout
			||
			(
				isRequestURLAppAPI(error.url)
				&&
				!isNaN(error.status)
				&&
				error.status >= 500
			)
		) {

			// isTimeout

			this.httpInterceptorSnackBarMessagesService.showSnackbar(
				// [todo] convert error types to ENUM
				'serverError',
				error.extras,
				error.error?.friendlyMessage
			);

		}

	}

	intercept(
		req: CustomHttpRequest,
		next: HttpHandler
	): Observable<HttpEvent<any>> {

		return next.handle(req)
			.pipe(
				timeout(110 * 1000)
				// catchError(err => {
				// 	console.log('timeout', (err instanceof TimeoutError), err);
				// 	return throwError(err);
				// })
			)
			.pipe(
				tap(
					() => {},
					(error: CustomHttpErrorResponse) => {
						// const httpExtras = this.appHTTPrequestExtraProperties.getRequestExtras(req);
						error.extras = req.extras;
						this.interceptErrors(error);
					}
				)
			);

	}

}
