import { NgModule, APP_INITIALIZER } from '@angular/core';
// import { TranslateLoadService } from '../i18n/translate-load.service';
import { AuthService, authAppInit } from './auth.service';



@NgModule({
	declarations: [],
	imports: [],
	providers: [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: authAppInit,
			deps: [AuthService]
		}
	]
})
export class AuthModule { }
