import { inject, NgModule } from '@angular/core';
import { CanActivateFn, Router, RouterModule, Routes } from '@angular/router';
import { appRoles } from 'src/app/auth/auth-roles.service';
import { RouteGuard } from '../routes.guard';
import { RoutesService } from '../routes.service';
import { AuthPermissionsService } from 'src/app/auth/auth-permissions.service';

const canActivateRedirHomeToRouteUniquePermission: CanActivateFn = () => {

	const router = inject(Router);
	const routesService = inject(RoutesService);
	const authPermissionsService = inject(AuthPermissionsService)

	const appRoutes: any[][] = Object.entries(routesService.getRoutes())
		.filter(
			(routeObj) =>
				authPermissionsService.has((routeObj[1] as any).permissionKey)
		)

	if(appRoutes.length === 1){
		router.navigate([appRoutes[0][1].path])
		return false;
	}else{
		return true;
	}

};

const routeConfig = {
	home: {
		path: 'home',
		label: 'Route.Home.Label'
	}
};

const routes: Routes = [
	{
		path: routeConfig.home.path,
		data: {
			appRole: appRoles.dashboard,
			basePath: routeConfig.home.path
		},
		canMatch: [RouteGuard],
		canActivate: [RouteGuard, canActivateRedirHomeToRouteUniquePermission],
		loadChildren: () => import('./home.module').then(
			(m) => m.HomeModule
		),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(
		routes,
		{}
	)]
})
export class HomeConfigRouting {
	constructor(private routesService: RoutesService){
		routesService.addRoute(routeConfig);
	}
}

export {
	routeConfig
};
