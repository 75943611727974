import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterModule, RouterStateSnapshot } from '@angular/router';
import { RouteGuard } from '../routes.guard';
import { RoutesService } from '../routes.service';
import { FilterMetrics } from '../dashboard-management/dashboard-management.service';
import { MetricsChartTimeUnit } from './metrics-chart.service';
import { appRoles } from 'src/app/auth/auth-roles.service';
import { RouteData } from '../routes.interface';
import { UserPermissions } from 'src/app/auth/auth.interface';

const routeConfig = {
	metricsChart: {
		path: 'metrics-chart',
		label: 'Route.MetricsChart.Label'
	}
};

const routeData: RouteData = {
	appRole: appRoles.dashboard,
	basePath: routeConfig.metricsChart.path,
	keepAfterLoginRedir: false,
	permissionKey: UserPermissions.routeDashboardManagement
};

@Injectable()
export class MetricChartResolverService  {
	constructor(private router: Router) {}

	public resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<any> {

		const metric = route.paramMap.get('metric');
		const timeunit = route.paramMap.get('time-unit');

		return new Promise((resolve, reject) => {

			if(
				!Object.values(FilterMetrics).includes(metric as FilterMetrics)
					||
					!Object.values(MetricsChartTimeUnit).includes(timeunit as MetricsChartTimeUnit)
			){
				this.router.navigate(['/']);
			}

			resolve(null);

		});

	}
}

@NgModule({
	imports: [
		RouterModule.forRoot([
			{
				path: `${routeConfig.metricsChart.path}/:metric/:time-unit`,
				data: routeData,
				canMatch: [RouteGuard],
				canActivate: [RouteGuard],
				resolve: { metricResolver: MetricChartResolverService },
				loadChildren: () => import('./metrics-chart.module')
					.then((m) => m.MetricsChartModule)
			},
		], {}),
	],
	providers: [
		MetricChartResolverService
	]
})
export class MetricsChartConfigRouting {
	constructor(private routesService: RoutesService){
		routesService.addRoute(routeConfig);
	}
}

export {
	routeConfig
};
