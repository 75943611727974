import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../../auth/auth.service';
import { MainControllerService } from 'src/app/components/main-controller/main-controller.service';

@Injectable({
	providedIn: 'root',
})
export class LoginGuard  {

	constructor(
		private router: Router,
		private authService: AuthService,
		private mainControllerService: MainControllerService
	){

	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {

		console.log('canActivate', route, state.url);

		if(Object.keys(route.queryParams).length){
			return true;
		}

		return this.missingToken();

	}

	// canLoad(route: Route): boolean {

	// 	console.log('canload', route.path);

	// 	return this.missingToken();

	// }

	missingToken(): boolean {

		if (!this.authService.isLogged()){
			this.mainControllerService.disableNavigationBar();
			return true;
		}

		this.router.navigate(['/']);

		return false;

	}

}
