import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { appRoles } from 'src/app/auth/auth-roles.service';
import { UserPermissions } from 'src/app/auth/auth.interface';
import { RouteGuard } from '../routes.guard';
import { RouteData } from '../routes.interface';
import { RoutesService } from '../routes.service';

const routeConfig = {
	assets: {
		path: 'assets-list',
		label: 'Route.Assets.Label',
		permissionKey: UserPermissions.routeAssets
	}
};

const routeData: RouteData = {
	appRole: appRoles.dashboard,
	basePath: routeConfig.assets.path,
	keepAfterLoginRedir: true,
	permissionKey: routeConfig.assets.permissionKey
};

const routes: Routes = [
	{
		path: routeConfig.assets.path,
		data: routeData,
		canMatch: [RouteGuard],
		canActivate: [RouteGuard],
		loadChildren: () => import('./assets.module').then(m => m.AssetsModule)
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AssetsConfigRouting {
	constructor(private routesService: RoutesService){
		routesService.addRoute(routeConfig);
	}
}
