import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
	providedIn: 'root'
})
export class HttpInterceptorSnackBarMessagesService {

	private messageType: object = {};

	constructor(
		private translocoService: TranslocoService,
		private matSnackBar: MatSnackBar
	) {

		this.translocoService.events$.subscribe(result => {
			if(result.type === 'translationLoadSuccess'){
				this.setMessageType();
			}
		});

	}

	setMessageType(){
		this.messageType = {
			// http status >= 200 <= 299
			success: {
				message: this.translocoService.translate(
					'DefaultHttpRequestMessages.success'
				)
			},
			// http status 400
			payloadError: {
				message: this.translocoService.translate(
					'DefaultHttpRequestMessages.payloadError'
				)
			},
			// http status 401
			unauthorized: {
				message: this.translocoService.translate(
					'DefaultHttpRequestMessages.unauthorized'
				)
			},
			// http status 403
			forbidden: {
				message: this.translocoService.translate(
					'DefaultHttpRequestMessages.forbidden'
				)
			},
			// http status 404
			notFound: {
				message: this.translocoService.translate(
					'DefaultHttpRequestMessages.notFound'
				)
			},
			serverError: {
				message: this.translocoService.translate(
					'DefaultHttpRequestMessages.serverError'
				)
			}
		};
	}

	private injectDefaultFriendlyMessageIntoResponse(type, httpExtras, responseFriendlyMessage){
		if(httpExtras.injectFriendlyMessageIntoResponseWhenEmpty){
			Object.assign(
				responseFriendlyMessage,

				this.messageType[type],

				responseFriendlyMessage.message
					? {message: responseFriendlyMessage.message}
					: {},

				httpExtras.friendlyMessage[type]
					? {message: httpExtras.friendlyMessage[type]}
					: {}
			);
		}
	}

	showSnackbar(type, httpExtras, responseFriendlyMessage: any = {}){


		httpExtras = httpExtras || {};

		if(!httpExtras.friendlyMessage){
			httpExtras.friendlyMessage = {};
		}

		if(type === 'success' && !httpExtras.friendlyMessage[type]){
			return;
		}

		this.injectDefaultFriendlyMessageIntoResponse(type, httpExtras, responseFriendlyMessage);

		// disabled snack message by type equals `false`
		if(httpExtras.friendlyMessage[type] === false){
			return;
		}

		if(!httpExtras.friendlyMessage[type]){
			httpExtras.friendlyMessage[type] = {};
		}

		const obj = Object.assign(
			this.messageType[type],
			responseFriendlyMessage,
			httpExtras.friendlyMessage[type]
		);

		this.matSnackBar.open(
			obj.message,
			'',
			{
				duration: 5000,
				panelClass: type
			}
		);

	}

}
