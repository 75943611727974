import { Injectable } from '@angular/core';
import { HttpHandler, HttpEvent, HttpRequest, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpInterceptorSnackBarMessagesService } from './snack-bar-messages';
import { isRequestURLAppAPI } from './request.helpers';
import { AppHTTPrequestExtraProperties } from '../helpers/app-http-request-extra-properties';
import { CustomHttpRequest, CustomHttpResponse } from './custom-erro-response';

@Injectable({
	providedIn: 'root'
})
export class Success200Service {

	constructor(
		private appHTTPrequestExtraProperties: AppHTTPrequestExtraProperties,
		private httpInterceptorSnackBarMessagesService: HttpInterceptorSnackBarMessagesService
	) { }

	interceptSuccess(response: CustomHttpResponse) {


		if (isRequestURLAppAPI(response.url) && response.status === 200) {

			this.httpInterceptorSnackBarMessagesService.showSnackbar(
				'success',
				response.extras
			);

		}

	}

	intercept(
		req: CustomHttpRequest,
		next: HttpHandler
	): Observable<HttpEvent<any>> {

		return next.handle(req).pipe(
			tap(
				(response: CustomHttpResponse) => {
					// const httpExtras = this.appHTTPrequestExtraProperties.getRequestExtras(req);
					response.extras = req.extras;
					this.interceptSuccess(response);
				},
				() => {}
			)
		);

	}

}

@Injectable()
export class CORSInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		if(isRequestURLAppAPI(req.url)){

			// Clone the request to add the new header.
			const corsReq = req.clone({
				headers: req.headers.set('Access-Control-Allow-Origin', '*')
			});

			// Send the newly created request
			return next.handle(corsReq);

		}else{
			return next.handle(req);
		}

	}
}
