import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { appRoles } from 'src/app/auth/auth-roles.service';
import { RouteGuard } from '../routes.guard';
import { RoutesService } from '../routes.service';
import { RouteData } from '../routes.interface';
import { UserPermissions } from 'src/app/auth/auth.interface';

const routeConfig = {
	techUserPlanning: {
		path: 'tech-user-planning',
		label: 'Route.TechUserPlanning.Label',
		permissionKey: UserPermissions.routeTechUserPlanning
	}
};

const routeData: RouteData = {
	appRole: appRoles.dashboard,
	basePath: routeConfig.techUserPlanning.path,
	keepAfterLoginRedir: true,
	permissionKey: routeConfig.techUserPlanning.permissionKey
};

const routes: Routes = [
	{
		path: routeConfig.techUserPlanning.path,
		data: routeData,
		canMatch: [RouteGuard],
		canActivate: [RouteGuard],
		loadChildren: () => import('./tech-user-planning.module').then(
			(m) => m.TechUserPlanningModule
		),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(
		routes,
		{}
	)]
})
export class TechUserPlanningConfigRouting {
	constructor(private routesService: RoutesService){
		routesService.addRoute(routeConfig);
	}
}

export {
	routeConfig
};
