import { environment } from 'src/environments/environment';

const isRequestURLAppAPI = url => {

	const regexp = new RegExp(environment.api.host);
	const regexpDotCom = new RegExp(environment.api.host.replace(/namoa\.io/,'namoadigital.com'));
	const regexpPortalWeb = new RegExp(environment.portalWeb);

	return regexp.test(url) || regexpDotCom.test(url) || regexpPortalWeb.test(url);

};

export {
	isRequestURLAppAPI
};
