import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { DashboardManagementConfigRouting } from './dashboard-management/dashboard-management.config.routing';

// import { AboutConfigRouting } from './about/about.config.routing';
import { LoginRoutingModule } from './login/login.config.routing';
import { MetricsChartConfigRouting } from './metrics-chart/metrics-chart.config.routing';
import { NformNonuserRouting } from './nform-nonuser/nform-nonuser.config.routing';
import { HomeConfigRouting, routeConfig as homeRouteConfig } from './home/home.config.routing';
import { DashboardOperationalConfigRouting } from './dashboard-operational/dashboard-operational.config.routing';
import { AssetsConfigRouting } from './assets/assets.config.routing';
import { RouteTicketsConfigRouting } from './tickets/tickets.config.routing';
import { NewTicketRoutingModule } from './new-ticket/new-ticket.config.routing';
import { ServiceOrderPlanningConfigRouting } from './service-order-planning/service-order-planning.config.routing';
import { FieldServiceConfigRouting } from './field-service/field-service.config.routing';
import { TechUserPlanningConfigRouting } from './tech-user-planning/tech-user-planning.config.routing';
import { ServiceOrderConfigRouting } from './service-order/service-order.config.routing';
import { TicketConfigRouting } from './service-order-planning/components/sidenav-asset-ticket/sidenav-asset-ticket.config.routing';

@NgModule({
	declarations: [],
	imports: [

		RouterModule.forRoot([
			// fallback route
			{
				path: '',
				redirectTo: homeRouteConfig.home.path,
				pathMatch: 'full'
			},
			{ path: '**', redirectTo: homeRouteConfig.home.path }
		], {}),

		LoginRoutingModule,
  	HomeConfigRouting,
		DashboardManagementConfigRouting,
		// AboutConfigRouting,
		MetricsChartConfigRouting,
		NformNonuserRouting,
  	DashboardOperationalConfigRouting,
		AssetsConfigRouting,
		RouteTicketsConfigRouting,
  	NewTicketRoutingModule,
		ServiceOrderPlanningConfigRouting,
		FieldServiceConfigRouting,
		TechUserPlanningConfigRouting,
		ServiceOrderConfigRouting,
		TicketConfigRouting
	],
	exports: []
})

export class AppRoutingModule { }
