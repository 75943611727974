import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RoutesService } from '../routes.service';
import { RouteGuard } from '../routes.guard';
import { appRoles } from 'src/app/auth/auth-roles.service';
import { RouteData } from '../routes.interface';
import { UserPermissions } from 'src/app/auth/auth.interface';

const routeConfig = {
	dashboardManagement: {
		path: 'dashboard-management',
		label: 'Route.Dashboard.Label',
		permissionKey: UserPermissions.routeDashboardManagement
	}
};

const routeData: RouteData = {
	appRole: appRoles.dashboard,
	basePath: routeConfig.dashboardManagement.path,
	keepAfterLoginRedir: false,
	permissionKey: routeConfig.dashboardManagement.permissionKey
};

@NgModule({
	imports: [
		RouterModule.forRoot([
			{
				path: 'dashboard',
				redirectTo: routeConfig.dashboardManagement.path
			},
			{
				path: routeConfig.dashboardManagement.path,
				data: routeData,
				canMatch: [RouteGuard],
				canActivate: [RouteGuard],
				loadChildren: () => import('./dashboard-management.module')
					.then(m => m.DashboardManagementModule)
			}
		], {})
	],
})

export class DashboardManagementConfigRouting {
	constructor(private routesService: RoutesService){
		routesService.addRoute(routeConfig);
	}
}

export {
	routeConfig
};
