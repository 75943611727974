import { NgModule } from '@angular/core';
import { DefaultTokenHeaderService } from './default-token-header.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CORSInterceptor, Success200Service } from './success-200.service';
import { Error400Service } from './error-400.service';
import { Error401Service } from './error-401.service';
import { Error403Service } from './error-403.service';
import { Error404Service } from './error-404.service';
import { ServerErrorService } from './server-error.service';

@NgModule({
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: DefaultTokenHeaderService,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: Success200Service,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: Error400Service,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: Error401Service,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: Error403Service,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: Error404Service,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ServerErrorService,
			multi: true
		},
		{ provide: HTTP_INTERCEPTORS, useClass: CORSInterceptor, multi: true }
	]
})
export class HttpInterceptorsModule { }
