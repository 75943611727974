import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RoutesService } from '../routes.service';
import { LoginGuard } from './login.guard';

const routeConfig = {
	login: {
		path: 'login',
		label: 'Login'
	}
};

@NgModule({
	imports: [RouterModule.forRoot([
		{
			path: routeConfig.login.path,
			data: {
				basePath: routeConfig.login.path
			},
			// canMatch: [LoginGuard],
			canActivate: [LoginGuard],
			loadChildren: () => import('./login.module').then(m => m.LoginModule)
		}
	], {})],
})

export class LoginRoutingModule {
	constructor(private routesService: RoutesService){
		routesService.addRoute(routeConfig);
	}
}
