import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteGuard } from '../routes.guard';
import { RoutesService } from '../routes.service';
import { appRoles } from 'src/app/auth/auth-roles.service';
import { UserPermissions } from 'src/app/auth/auth.interface';
import { RouteData } from '../routes.interface';

const routeConfig = {
	fieldService: {
		path: 'field-service',
		label: 'Route.FieldService.Label',
		permissionKey: UserPermissions.routeFieldService
	}
};


const routeData: RouteData = {
	appRole: appRoles.dashboard,
	basePath: routeConfig.fieldService.path,
	keepAfterLoginRedir: true,
	permissionKey: routeConfig.fieldService.permissionKey
};

const routes: Routes = [
	{
		path: routeConfig.fieldService.path,
		data: routeData,
		canMatch: [RouteGuard],
		canActivate: [RouteGuard],
		loadChildren: () => import('./field-service.module').then(
			m => m.FieldServiceModule
		),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class FieldServiceConfigRouting {
	constructor(private routesService: RoutesService){
		routesService.addRoute(routeConfig);
	}
}

export {
	routeConfig
};
