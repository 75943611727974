import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrls } from 'src/api-urls/urls';
import { AppHttpRequestService } from 'src/app/helpers/app-http-request';
import { AuthTokenQrcodeAPIGetResponse } from './auth-token-qrcode.interface';

@Injectable({
	providedIn: 'root'
})
export class APIPortalAuthTokenQrcodeService {

	constructor(
		private appHttpRequestService: AppHttpRequestService
	) { }

	get(
		attrs: APIPortalAuthTokenQrcodeServiceGETAttrs
	): Observable<HttpResponse<AuthTokenQrcodeAPIGetResponse>> {

		const HEADER_nform = JSON.stringify(attrs.headers.nform);

		return this.appHttpRequestService.getRequest({
			method: 'get',
			url: ApiUrls.portal.authTokenQRcode,
			headers: Object.assign(
				{},
				attrs.headers,
				{nform: HEADER_nform}
			),
			extras: attrs.extras || {}
		});

	}


}

export interface APIPortalAuthTokenQrcodeServiceGETAttrs {
	[prop: string]: any;
	headers: APIPortalAuthTokenQrcodeServiceGETHeaders;
}

export interface APIPortalAuthTokenQrcodeServiceGETHeaders {
	nform: {
		P_BARCODE_ENC: string;
		P_PROCESS: string;
	};
}
