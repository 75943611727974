import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import localePT from '@angular/common/locales/pt';
import localeDE from '@angular/common/locales/de';

import { AppRoutingModule } from './routes/app-routing.module';

import { AppComponent } from './app.component';
import { HttpInterceptorsModule } from './http-interceptors/http-interceptors.module';
import { SharedModule } from './shared.module';
import { AuthModule } from './auth/auth.module';

import { OverlayContainer } from '@angular/cdk/overlay';
import { CdkOverlayContainer } from './components/main-controller/cdk-overlay-container/cdk-overlay-container.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';

registerLocaleData(localePT);
registerLocaleData(localeDE);

const locales = ['pt', 'de'];
let locale = 'en-US';
locales.map(item => {
	const regexp = new RegExp(`^${item}`);
	if(regexp.test(navigator.language)){
		locale = item;
	}
});

@NgModule({ declarations: [
	AppComponent
],
bootstrap: [AppComponent], imports: [BrowserModule,
	BrowserAnimationsModule,
	AppRoutingModule,
	HttpInterceptorsModule,
	AuthModule,
	SharedModule,
	ReactiveFormsModule,
	FormlyModule.forRoot(),
	FormlyMaterialModule], providers: [
	{ provide: LOCALE_ID, useValue: locale },
	{ provide: OverlayContainer, useClass: CdkOverlayContainer },
	provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())
] })
export class AppModule { }
