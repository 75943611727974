import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { appRoles } from 'src/app/auth/auth-roles.service';
import { RouteGuard } from 'src/app/routes/routes.guard';
import { RouteData } from 'src/app/routes/routes.interface';
import { RoutesService } from 'src/app/routes/routes.service';
import { ServiceOrderPlanningSideNavAssetTicketComponent } from './sidenav-asset-ticket.component';
import { SharedModule } from 'src/app/shared.module';

const routeConfig = {
	ticket: {
		path: 'ticket',
		label: 'Route.ServiceOrderPlanning.Label'
	}
};

const routeData: RouteData = {
	appRole: appRoles.dashboard,
	basePath: routeConfig.ticket.path,
	keepAfterLoginRedir: true
};

const routes: Routes = [
	{
		path: `${routeConfig.ticket.path}/:ticketId`,
		data: routeData,
		canMatch: [RouteGuard],
		canActivate: [RouteGuard],
		component: ServiceOrderPlanningSideNavAssetTicketComponent
	}
];

@NgModule({
	declarations: [
		ServiceOrderPlanningSideNavAssetTicketComponent
	],
	imports: [
		SharedModule,
		RouterModule.forRoot(routes)
	],
	exports: [
		RouterModule,
		ServiceOrderPlanningSideNavAssetTicketComponent
	]
})
export class TicketConfigRouting {
	constructor(private routesService: RoutesService){
		routesService.addRoute(routeConfig);
	}
}
