import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { appRoles } from 'src/app/auth/auth-roles.service';
import { UserPermissions } from 'src/app/auth/auth.interface';
import { RouteGuard } from '../routes.guard';
import { RouteData } from '../routes.interface';
import { RoutesService } from '../routes.service';

const routeConfig = {
	dashboardOperational: {
		path: 'dashboard-operational',
		label: 'Route.DashboardOperational.Label',
		permissionKey: UserPermissions.routeDashboardOperational
	}
};

const routeData: RouteData = {
	appRole: appRoles.dashboard,
	basePath: routeConfig.dashboardOperational.path,
	keepAfterLoginRedir: true,
	permissionKey: routeConfig.dashboardOperational.permissionKey
};

const routes: Routes = [
	{
		path: 'management-dashboard',
		redirectTo: routeConfig.dashboardOperational.path
	},
	{
		path: routeConfig.dashboardOperational.path,
		data: routeData,
		canMatch: [RouteGuard],
		canActivate: [RouteGuard],
		loadChildren: () =>
			import('./dashboard-operational.module').then(
				(m) => m.DashboardOperationalModule
			),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(
		routes,
		{}
	)],
	exports: [RouterModule],
})
export class DashboardOperationalConfigRouting {
	constructor(private routesService: RoutesService){
		routesService.addRoute(routeConfig);
	}
}

export {
	routeConfig
};
