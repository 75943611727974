import { Component, Inject, OnInit } from '@angular/core';
import { RoutesService } from './routes/routes.service';
import { I18nService } from './i18n/i18n.service';
import { TranslocoService } from '@jsverse/transloco';
import { IconSvgService } from './components/icon-svg/icon-svg.service';
import { AppDeviceTypeService } from './app.device-type.service';
import { ActivationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AppService } from './app.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	currentDevice;
	path = '';
	theme = '';

	constructor(
		private routesService: RoutesService,
		private i18nService: I18nService,
		private translocoService: TranslocoService,
		private appDeviceTypeService: AppDeviceTypeService,
		private iconSvgService: IconSvgService,
		private router: Router,
		private appService: AppService,
		@Inject(DOCUMENT) private document: Document,
	) {

		console.log(AppComponent.name, routesService.getRoutes());
		console.log(AppComponent.name, i18nService.detectedLocale);
		// console.log(this.translocoService.translate('title'));

		this.setCurrentDevice();

		this.router.events.subscribe(event => {
			if(event instanceof ActivationEnd){
				this.path = event.snapshot.data.basePath;
			}
		});

		[ 'drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop' ].forEach( event => {
			document.querySelector('body').addEventListener(event, (e: DragEvent) => {
				e.preventDefault();
				e.stopPropagation();
			})
		})

	}

	ngOnInit(): void {
		// this.loadStyle('namoa');
		this.appService.setWhiteLabel();

		const whiteLabelProperties = this.appService.getWhiteLabelProperties();
		let themeFile = whiteLabelProperties?.subdomain;

		if(themeFile){
			themeFile = `customer-${themeFile}`
		}

		if(themeFile){
			this.loadStyle(themeFile, whiteLabelProperties.styleVersion);
		}else{
			this.loadStyle('namoa', 1);
		}

	}

	setCurrentDevice = () =>
		this.currentDevice = this.appDeviceTypeService.device;

	getRoute = name => this.routesService.getRoute(name);

	loadStyle(styleName: string, version: number) {

		this.theme = styleName;

		const head = this.document.getElementsByTagName('head')[0];

		const themeLink = this.document.getElementById(
			'style-theme'
		) as HTMLLinkElement;

		if (themeLink) {
			themeLink.href = `theme-${styleName}-v${version}.css`;
		} else {
			const style = this.document.createElement('link');
			style.id = 'style-theme';
			style.rel = 'stylesheet';
			style.href = `theme-${styleName}-v${version}.css`;
			head.appendChild(style);
		}

	}

}
