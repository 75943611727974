import { Injectable } from '@angular/core';
import { HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { isRequestURLAppAPI } from './request.helpers';
import { HttpInterceptorSnackBarMessagesService } from './snack-bar-messages';
import { CustomHttpErrorResponse, CustomHttpRequest } from './custom-erro-response';
import { AppHTTPrequestExtraProperties } from '../helpers/app-http-request-extra-properties';

@Injectable({
	providedIn: 'root'
})
export class Error403Service {

	constructor(
		private appHTTPrequestExtraProperties: AppHTTPrequestExtraProperties,
		private httpInterceptorSnackBarMessagesService: HttpInterceptorSnackBarMessagesService
	) { }

	interceptErrors(error: CustomHttpErrorResponse) {

		if (isRequestURLAppAPI(error.url) && error.status === 403) {

			this.httpInterceptorSnackBarMessagesService.showSnackbar(
				'forbidden',
				error.extras,
				error.error.friendlyMessage
			);

		}

	}

	intercept(
		req: CustomHttpRequest,
		next: HttpHandler
	): Observable<HttpEvent<any>> {

		return next.handle(req).pipe(
			tap(
				() => {},
				(error: CustomHttpErrorResponse) => {
					// const httpExtras = this.appHTTPrequestExtraProperties.getRequestExtras(req);
					error.extras = req.extras;
					this.interceptErrors(error);
				}
			)
		);

	}

}
